import React, { useEffect, useState } from 'react';
import { Router } from '@reach/router';
import { useSelector } from 'react-redux';

import { PremiumRoute } from '../components/router';
import { ContenidoPremiumLayout } from '../layouts';
import { api } from '../services';

const ContenidoPremiumPage = props => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const currentLang = useSelector(({ config }) => config.language);
  const currentToken = useSelector(({ login }) => login.token);
  const { fetchPremiumContent } = api();

  useEffect(() => {
    let ignore = false;
    const fetchData = async ignore => {
      try {
        let response = null;
        response = await fetchPremiumContent(
          currentToken,
          currentLang,
          props.id
        );

        if (!ignore) {
          setData(response);
        }
      } catch (e) {
        setError(e.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetchData(ignore);
    return () => {
      ignore = true;
    };
  }, [currentLang, props, fetchPremiumContent]);

  return (
    <Router>
      <PremiumRoute
        path="/contenido-premium/:id"
        component={ContenidoPremiumLayout}
        id={props.id}
        data={data}
        loading={loading}
        error={error}
      />
    </Router>
  );
};

export default ContenidoPremiumPage;
